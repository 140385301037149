export default {
  GetPageId(page) {
    //return page.$router.currentRoute.path.replace('/([d]*).html?([^?]*)|/([d]*).html', '/edit.html')
    return page.$router.currentRoute.path.replace(/\/\d.+html/g, '/edit.html')
  },

  async confirm(_this, config) {
    return new Promise(resolve => {
      _this({
        title: config.title,
        content: config.content,
        onOk: () => {
          resolve(true)
        },
        onCancel() {
          resolve(false)
        }
      })
    })
  },

  filterOption(input, option) {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }
}
