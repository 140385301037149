<template>
  <a-card>
    <h3>Khách hàng đặc biệt</h3>
    <a-row :gutter="8" style="margin-top: 10px">
      <a-col :xs="24" :sm="24" :md="12" :lg="8" :xl="4" :xxl="3">
        <a-date-picker
          @change="handleChangeFromDate"
          class="form-item"
          placeholder="Ngày bắt đầu"
          :value="fromDate"
          format="DD/MM/YYYY"
        />
      </a-col>
      <a-col :xs="24" :sm="24" :md="12" :lg="8" :xl="4" :xxl="3">
        <a-date-picker
          @change="handleChangeToDate"
          class="form-item"
          :value="toDate"
          placeholder="Ngày kết thúc"
          format="DD/MM/YYYY"
        />
      </a-col>
      <a-col :xs="24" :sm="24" :md="12" :lg="8" :xl="4" :xxl="3">
        <a-select
          class="form-item"
          v-model="queryParam.salonId"
          placeholder="Chọn salon"
          allowClear
          showSearch
          :filterOption="filterOption"
        >
          <a-select-option v-for="salon in salonOptions" :value="salon.id" :key="salon.id">{{
            salon.shortName
          }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :xs="24" :sm="24" :md="12" :lg="8" :xl="4" :xxl="3">
        <a-input placeholder="Số điện thoại" class="form-item" v-model="queryParam.customerPhone" allowClear />
      </a-col>
      <a-col :xs="24" :sm="24" :md="12" :lg="8" :xl="4" :xxl="3">
        <a-select
          class="form-item"
          v-model="queryParam.typeErrorId"
          placeholder="Chọn lý do"
          allowClear
          showSearch
          :filterOption="filterOption"
        >
          <a-select-option v-for="salon in errorCutReasonOptions" :value="salon.id" :key="salon.id">{{
            salon.reason
          }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :xs="24" :sm="24" :md="12" :lg="8" :xl="4" :xxl="3">
        <a-select
          class="form-item"
          v-model="queryParam.browerId"
          placeholder="Chọn duyệt"
          allowClear
          showSearch
          :filterOption="filterOption"
        >
          <a-select-option v-for="status in statusOptions" :value="status.id" :key="status.id">{{
            status.text
          }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :xs="12" :sm="12" :md="12" :lg="4" :xl="4" :xxl="2">
        <a-button class="form-item" icon="search" type="primary" @click="getData">Xem dữ liệu</a-button>
      </a-col>
      <a-col :xs="12" :sm="12" :md="12" :lg="4" :xl="4" :xxl="2">
        <a-button class="form-item" icon="delete" @click="resetFilter">Reset Filter</a-button>
      </a-col>
      <a-col :xs="12" :sm="12" :md="12" :lg="4" :xl="4" :xxl="2">
        <a-button v-if="$can($permission.add, $route.path)" class="form-item" icon="user-add" @click="handleAddItem"
          >Thêm mới</a-button
        >
      </a-col>
    </a-row>
    <a-row :gutter="8" style="margin-top: 20px">
      <a-col>
        <a-table
          :columns="columns"
          :data-source="data"
          :rowKey="(record, index) => index"
          :loading="loading"
          size="small"
          :pagination="false"
          bordered
          @expand="handleClickRowExpand"
        >
          <span slot="no" slot-scope="text, record, index">{{ getNo(index) }}</span>
          <span slot="sendDate" slot-scope="text, record">{{ record.sendDate | formatDate }}</span>
          <span slot="action" slot-scope="text, record">
            <a-icon v-if="$can($permission.edit, $route.path)" type="edit" @click="handleEdit(record)" />
            <a-divider type="vertical" />
            <a-icon v-if="$can($permission.delete, $route.path)" type="delete" @click="handleDelete(record)" />
          </span>
          <span slot="approve" v-if="record.browse_ID === 1" slot-scope="text, record">
            <a-button v-if="isApprove" type="primary" size="small" @click="handleApproved(record, 2)">Duyệt</a-button>
            <a-button
              v-if="isApprove"
              type="danger"
              size="small"
              @click="handleApproved(record, 3)"
              style="margin-left: 5px"
              >Không duyệt</a-button
            >
          </span>

          <a-table
            slot="expandedRowRender"
            :columns="innerColumns"
            :dataSource="dataDetail"
            :pagination="false"
            size="small"
            :rowKey="(record, index) => index"
            bordered
          >
            <span slot="no" slot-scope="text, record, index">{{ index + 1 }}</span>
            <span slot="cutErrorDate" slot-scope="text, record">{{ record.cutErrorDate | formatTime }}</span>
          </a-table>
        </a-table>
        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryParam.page"
          :limit.sync="queryParam.limit"
          @pagination="getData"
        />
      </a-col>
    </a-row>
    <CustomerSpecialAddDialog v-if="visibleAddItem" :visible.sync="visibleAddItem" @realoadData="handleAddSuccess" />
    <CustomerSpecialUpdateDialog
      v-if="visibleUpdateItem"
      :visible.sync="visibleUpdateItem"
      :id="itemUpdate.id"
      @realoadData="handleAddSuccess"
    />
  </a-card>
</template>

<script>
import CustomerSpecialAddDialog from './CustomerSpecialAddDialog'
import { mapGetters } from 'vuex'
import CustomerSpecialUpdateDialog from './CustomerSpecialUpdateDialog'
import Pagination from '@/components/Pagination'
import Utils from '@/utils/others'
import moment from 'moment'
import { RepositoryFactory } from '@/api/RepositoryFactory'
const CustomerSpecialRepository = RepositoryFactory.get('customerSpecial')
const ErrorCutReasonRepo = RepositoryFactory.get('errorCutReason')
const SalonRepo = RepositoryFactory.get('salon')
const PermissionRepository = RepositoryFactory.get('permission')

export default {
  components: { CustomerSpecialAddDialog, Pagination, CustomerSpecialUpdateDialog },
  computed: {
    ...mapGetters(['user']),
    isApprove: function() {
      console.log(this.user.roles)
      if (this.user.roles && Array.isArray(this.user.roles)) {
        const roles = this.user.roles.filter(x => {
          return x.name !== 'ASM' && x.name !== 'ADMIN'
        })
        if (roles.length > 0) {
          return true
        }
      }
      return false
    }
  },
  data() {
    return {
      fromDate: undefined,
      toDate: undefined,
      queryParam: {
        page: 1,
        limit: 10
      },
      total: 0,
      loading: false,
      columns: [
        {
          title: 'STT',
          scopedSlots: { customRender: 'no' }
        },
        {
          title: 'Khách hàng',
          dataIndex: 'fullname'
        },
        {
          title: 'Điện thoại',
          dataIndex: 'phone'
        },
        {
          title: 'Nhân viên (mới)',
          dataIndex: 'lstErrorStaff_Name'
        },
        {
          title: 'Lý do (mới)',
          dataIndex: 'lstErrorTypeCutReason'
        },
        {
          title: 'Ghi chú',
          dataIndex: 'reasonDiff'
        },
        {
          title: 'Ngày dùng miễn phí gần nhất',
          scopedSlots: { customRender: 'sendDate' }
        },
        {
          title: 'Hành động',
          scopedSlots: { customRender: 'action' },
          width: '70px'
        },
        {
          title: 'Duyệt',
          scopedSlots: { customRender: 'approve' },
          width: '170px'
        }
      ],
      innerColumns: [
        {
          title: 'STT',
          scopedSlots: { customRender: 'no' }
        },
        {
          title: 'Ngày cắt',
          scopedSlots: { customRender: 'cutErrorDate' }
        },
        {
          title: 'Nhân viên',
          dataIndex: 'staffName'
        },
        {
          title: 'Salon',
          dataIndex: 'salonName'
        },
        {
          title: 'Giảm giá DV',
          dataIndex: 'serviceDiscountPercent'
        },
        {
          title: 'Giảm giá MP',
          dataIndex: 'productDiscoutPercent'
        },
        {
          title: 'Số lần miễn phí',
          dataIndex: 'numberFree'
        }
      ],
      data: [],
      dataDetail: [],
      reasonOptions: [],
      statusOptions: [
        {
          id: 1,
          text: 'Chưa duyệt'
        },
        {
          id: 2,
          text: 'Đã duyệt'
        },
        {
          id: 3,
          text: 'Không duyệt'
        }
      ],
      salonOptions: [],
      errorCutReasonOptions: [],
      visibleAddItem: false,
      visibleUpdateItem: false,
      itemUpdate: undefined
    }
  },
  methods: {
    async handleClickRowExpand(expanded, record) {
      this.dataDetail = []
      if (!expanded) {
        return
      }
      try {
        const response = await CustomerSpecialRepository.getDetail(record.id)
        this.dataDetail = response
      } catch (error) {
        console.log(error)
      }
    },
    handleChangeFromDate(date) {
      this.fromDate = date
    },

    handleChangeToDate(date) {
      this.toDate = date
    },

    async getData(isResetPage = false) {
      if (isResetPage) {
        this.queryParam.page = 1
        this.total = 0
      }
      this.loading = true
      this.data = []
      if (this.fromDate && this.toDate) {
        this.queryParam = {
          ...this.queryParam,
          fromDate: moment(this.fromDate, 'DD/MM/YYYY').format('YYYY/MM/DD'),
          toDate: moment(this.toDate, 'DD/MM/YYYY').format('YYYY/MM/DD')
        }
      }
      if (!this.queryParam.salonId) {
        const salonIds = this.salonOptions.map(x => {
          return x.id
        })
        this.queryParam.salonIdStrings = salonIds.toString()
      }

      try {
        const response = await CustomerSpecialRepository.getList(this.queryParam)
        this.data = response.results
        this.total = response.total
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    resetFilter() {
      this.queryParam = {
        page: 1,
        limit: 10
      }
      this.fromDate = undefined
      this.toDate = undefined
    },
    handleAddItem() {
      this.visibleAddItem = true
    },
    handleEdit(record) {
      this.itemUpdate = record
      this.visibleUpdateItem = true
    },

    async handleApproved(record, browerId) {
      try {
        const isConfirm = await Utils.confirm(this.$confirm, {
          title: 'Xác nhận',
          content: 'Bạn có chắc chắn muốn duyệt không?'
        })
        if (!isConfirm) {
          return
        }
        await CustomerSpecialRepository.approve(record.id, { browerId })
        this.$message.success('Duyệt thành công.')
        this.getData()
      } catch (error) {
        this.$message.error('Duyệt thất bại.')
      }
    },

    async handleDelete(record) {
      try {
        const isConfirm = await Utils.confirm(this.$confirm, {
          title: 'Xác nhận',
          content: 'Bạn có chắc chắn muốn xóa khách hàng này không?'
        })
        if (!isConfirm) {
          return
        }
        await CustomerSpecialRepository.delete(record.id)
        this.$message.success('Xóa khách hàng thành công.')
        await this.getData()
      } catch (error) {
        console.log(error)
      }
    },

    async getErrorCutReason() {
      const response = await ErrorCutReasonRepo.getListSelect()
      this.errorCutReasonOptions = response
    },

    async getSalon() {
      try {
        if (this.$can(this.$permission.viewAllData, this.$route.path)) {
          const response = await SalonRepo.getListSelect()
          this.salonOptions = response
        } else {
          this.salonOptions = this.user.listSalon.map(x => {
            return { id: x.id, shortName: x.salonName }
          })
        }
      } catch (error) {
        console.log(error)
      }
    },

    handleAddSuccess(state) {
      if (state) {
        this.getData()
      }
    },

    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    getNo(index) {
      return this.queryParam.limit * (this.queryParam.page - 1) + (index + 1)
    }
  },
  async created() {
    this.getErrorCutReason()
    this.getSalon()
    const { roles } = await PermissionRepository.getRoles(this.user.userId)
    this.$store.commit('SET_USER_INFO', {
      ...this.user,
      roles
    })
    console.log(this.user)
  }
}
</script>

<style>
.form-item {
  margin: 5px 0 5px 0;
  width: 100%;
}
</style>
