<template>
  <a-modal
    v-model="localVisible"
    title="Cập nhật khách hàng đặc biệt"
    on-ok="handleOk"
    on-cancel="handleCancel"
    :width="this.isMobile() ? '100%' : this.isTablet() ? '70%' : '40%'"
    style="top: 20px"
    :maskClosable="false"
    :bodyStyle="{ height: '600px', overflowY: 'auto' }"
  >
    <a-row :gutter="8">
      <a-col>
        <label class="form-item-lable"
          >Khách hàng <strong>{{ item.fullname }}</strong></label
        >
        <a-input
          v-model="item.customerPhone"
          placeholder="Nhập điện thoại khách hàng"
          :maxLength="10"
          :disabled="true"
        />
      </a-col>
      <a-col>
        <label class="form-item-lable">Ngày cắt lỗi</label>
        <a-date-picker :defaultValue="fromDate" style="width: 100%" @change="handleChangeCutDate" />
      </a-col>
      <a-col>
        <label class="form-item-lable">Salon</label>
        <a-select
          style="width: 100%"
          placeholder="Chọn salon"
          allowClear
          showSearch
          v-model="item.salonId"
          :filterOption="filterOption"
          @change="handleChangeSalon"
        >
          <a-select-option v-for="salon in salonOptions" :value="salon.id" :key="salon.id">{{
            salon.shortName
          }}</a-select-option>
        </a-select>
      </a-col>
      <a-col>
        <label class="form-item-lable">Nhân viên</label>
        <a-select
          style="width: 100%"
          placeholder="Chọn nhân viên"
          mode="multiple"
          allowClear
          showSearch
          @change="handleChangeStaff"
          v-model="staffSelects"
          :filterOption="filterOption"
        >
          <a-select-option v-for="staff in staffOptions" :value="staff.id" :key="staff.id">{{
            staff.fullName
          }}</a-select-option>
        </a-select>
      </a-col>
      <a-col>
        <label class="form-item-lable">Số lần mời dùng dịch vụ</label>
        <a-input :value="item.numberFree" @input="item.numberFree = $event.target.value" type="number" />
      </a-col>
    </a-row>
    <a-row>
      <a-col :md="12">
        <label class="form-item-lable">Giảm giá MP (%)</label>
        <a-input
          :value="item.productDiscountPercent"
          @input="item.productDiscountPercent = $event.target.value"
          type="number"
        />
      </a-col>
      <a-col :md="12">
        <label class="form-item-lable">Giảm giá DV (%)</label>
        <a-input
          :value="item.serviceDiscountPercent"
          @input="item.serviceDiscountPercent = $event.target.value"
          type="number"
        />
      </a-col>
    </a-row>
    <a-row>
      <a-col>
        <label class="form-item-lable">Lý do</label>
        <a-select
          style="width: 100%"
          allowClear
          showSearch
          :filterOption="filterOption"
          mode="multiple"
          v-model="errorTypeSelects"
          @change="handleChangeReason"
        >
          <a-select-option v-for="errorCut in errorCutReasonOptions" :value="errorCut.id" :key="errorCut.id">{{
            errorCut.reason
          }}</a-select-option>
        </a-select>
      </a-col>
      <a-col>
        <label class="form-item-lable">Ghi chú</label>
        <a-textarea allow-clear v-model="item.reason" />
      </a-col>
    </a-row>
    <template slot="footer">
      <a-button key="back" @click="handleCancel">
        Hủy bỏ
      </a-button>
      <a-button key="submit" type="primary" :loading="loading" @click="onSubmit">
        Lưu lại
      </a-button>
    </template>
  </a-modal>
</template>
<script>
import { mixin, mixinDevice } from '@/utils/mixin'
import moment from 'moment'
import { mapGetters } from 'vuex'
import { RepositoryFactory } from '@/api/RepositoryFactory'
const CustomerSpecialRepository = RepositoryFactory.get('customerSpecial')
const StaffRepo = RepositoryFactory.get('staff')
const SalonRepo = RepositoryFactory.get('salon')
const ErrorCutReasonRepo = RepositoryFactory.get('errorCutReason')

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      default: 0
    }
  },
  mixins: [mixin, mixinDevice],
  computed: {
    localVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    ...mapGetters(['user'])
  },
  data() {
    return {
      loading: false,
      data: [],
      salonOptions: [],
      staffOptions: [],
      errorCutReasonOptions: [],
      staffSelects: [],
      errorTypeSelects: [],
      staffs: [],
      salons: [],
      fromDate: moment(),
      customerName: undefined,
      item: {}
    }
  },
  watch: {
    'item.numberFree': function(value) {
      value = parseInt(value)
      if (Number.isNaN(value) || !Number.isInteger(value) || value < 0) {
        this.item.numberFree = 0
      } else {
        if (value > 100) {
          this.$message.warn('Vui lòng nhập giá trị nhỏ hơn hoặc bằng 100')
          this.item.numberFree = 0
          return
        }
        this.item.numberFree = value
      }
    },
    'item.productDiscountPercent': function(value) {
      value = parseInt(value)
      if (Number.isNaN(value) || !Number.isInteger(value) || value < 0) {
        this.item.productDiscountPercent = 0
      } else {
        if (value > 100) {
          this.$message.warn('Vui lòng nhập giá trị nhỏ hơn hoặc bằng 100')
          this.item.productDiscountPercent = 0
          return
        }
        this.item.productDiscountPercent = value
      }
    },
    'item.serviceDiscountPercent': function(value) {
      value = parseInt(value)
      if (Number.isNaN(value) || !Number.isInteger(value) || value < 0) {
        this.item.serviceDiscountPercent = 0
      } else {
        if (value > 100) {
          this.$message.warn('Vui lòng nhập giá trị nhỏ hơn hoặc bằng 100')
          this.item.serviceDiscountPercent = 0
          return
        }
        this.item.serviceDiscountPercent = value
      }
    }
  },
  methods: {
    handleChangeCutDate(date) {
      this.fromDate = date
    },

    handleCancel() {
      this.localVisible = false
    },

    async onSubmit() {
      try {
        if (!this.isValidInput()) {
          return
        }
        this.loading = true
        await CustomerSpecialRepository.update(this.id, this.item)
        this.$message.success('Cập nhật thành công.')
        this.localVisible = false
        this.$emit('realoadData', true)
      } catch (error) {
        console.log(error)
      }
      this.loading = false
    },

    async getById() {
      try {
        const response = await CustomerSpecialRepository.getById(this.id)
        this.item = response
        this.handleChangeSalon(this.item.salonId)
        this.fromDate = moment(this.item.cutErrorDate)
        if (Array.isArray(this.item.staffErrors) && this.item.staffErrors.length > 0) {
          this.staffSelects = this.item.staffErrors.map(x => {
            return x.id
          })
          console.log(this.staffSelects)
        }
        if (Array.isArray(this.item.typeErrors) && this.item.typeErrors.length > 0) {
          this.errorTypeSelects = this.item.typeErrors.map(x => {
            return x.id
          })
          console.log(this.staffSelects)
        }
      } catch (error) {
        console.log(error)
      }
    },

    isValidInput() {
      if (!this.item.customerId) {
        this.$message.warn('Vui lòng nhập số điện thoại.')
        return false
      }
      if (!this.item.cutErrorDate) {
        this.$message.warn('Vui lòng chọn ngày cắt lỗi.')
        return false
      }
      if (!this.item.salonId) {
        this.$message.warn('Vui lòng chọn salon.')
        return false
      }
      if (!this.item.staffErrors || this.item.staffErrors.length === 0) {
        this.$message.warn('Vui lòng chọn nhân viên.')
        return false
      }

      if (!this.item.typeErrors || this.item.typeErrors.length === 0) {
        this.$message.warn('Vui lòng chọn lý do.')
        return false
      }

      if (!this.item.reason) {
        this.$message.warn('Vui lòng nhập lý do.')
        return false
      }
      return true
    },
    async getSalon() {
      try {
        if (this.$can(this.$permission.viewAllData, this.$route.path)) {
          const response = await SalonRepo.getListSelect()
          this.salonOptions = response
        } else {
          this.salonOptions = this.user.listSalon.map(x => {
            return { id: x.id, shortName: x.salonName }
          })
        }
      } catch (error) {
        console.log(error)
      }
    },

    async getStaff() {
      try {
        const response = await StaffRepo.getListSelect()
        this.staffs = response
      } catch (error) {
        console.log(error)
      }
    },

    async getErrorCutReason() {
      const response = await ErrorCutReasonRepo.getListSelect()
      this.errorCutReasonOptions = response
    },

    handleChangeSalon(value) {
      this.staffOptions = this.staffs.filter(x => x.salonId === value)
    },

    handleChangeStaff(value) {
      if (value) {
        this.item.staffErrors = this.staffOptions.filter(x => {
          return value.indexOf(x.id) !== -1
        })
      }
    },

    handleChangeReason(value) {
      if (value) {
        this.item.typeErrors = this.errorCutReasonOptions.filter(x => {
          return value.indexOf(x.id) !== -1
        })
      }
    },

    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  },
  async created() {
    await this.getSalon()
    await this.getStaff()
    await this.getErrorCutReason()
    await this.getById()
  }
}
</script>
<style scoped>
.form-item-input {
  margin: 5px 0 5px 0;
  width: 100%;
}
.form-item-lable {
  display: inline-block;
  margin: 5px 0 5px 0;
}
</style>
