<template>
  <div class="pagination-container">
    <a-pagination
      showSizeChanger
      :defaultCurrent="currentPage"
      :pageSize.sync="pageSize"
      :pageSizeOptions="pageSizes"
      :total="total"
      :current.sync="currentPage"
      @change="handleCurrentChange"
      @showSizeChange="handleSizeChange"
      :locale="locale"
      :responsive="true"
    />
  </div>
</template>

<script>
import vi_VN from 'ant-design-vue/lib/locale-provider/vi_VN'
export default {
  name: 'Pagination',
  props: {
    total: {
      required: true,
      type: Number
    },
    page: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: 10
    },
    pageSizes: {
      type: Array,
      default() {
        return ['5', '10', '20', '30', '50', '100', '200']
      }
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.page
      },
      set(val) {
        this.$emit('update:page', val)
      }
    },
    pageSize: {
      get() {
        return this.limit
      },
      set(val) {
        this.$emit('update:limit', val)
      }
    }
  },
  data() {
    return {
      locale: vi_VN
    }
  },
  methods: {
    handleSizeChange() {
      this.currentPage = 1
      this.$emit('pagination', true)
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.$emit('pagination', false)
    }
  }
}
</script>

<style scoped>
.pagination-container {
  background: #fff;
  padding: 32px 16px;
  text-align: center;
}
.pagination-container.hidden {
  display: none;
}
</style>
