<template>
  <a-modal
    v-model="localVisible"
    title="Thêm mới khách hàng đặc biệt"
    on-ok="handleOk"
    on-cancel="handleCancel"
    :width="this.isMobile() ? '100%' : this.isTablet() ? '70%' : '40%'"
    style="top: 20px"
    :maskClosable="false"
    :bodyStyle="{ height: '600px', overflowY: 'auto' }"
  >
    <a-row :gutter="8">
      <a-col>
        <label class="form-item-lable"
          >Khách hàng <strong>{{ customerName }}</strong></label
        >
        <a-input placeholder="Nhập điện thoại khách hàng" @blur="handleChangePhone" :maxLength="10" />
      </a-col>
      <a-col>
        <label class="form-item-lable">Ngày cắt lỗi</label>
        <a-date-picker style="width: 100%" @change="handleChangeCutDate" />
      </a-col>
      <a-col>
        <label class="form-item-lable">Salon</label>
        <a-select
          style="width: 100%"
          placeholder="Chọn salon"
          allowClear
          showSearch
          :filterOption="filterOption"
          @change="handleChangeSalon"
        >
          <a-select-option v-for="salon in salonOptions" :value="salon.id" :key="salon.id">{{
            salon.shortName
          }}</a-select-option>
        </a-select>
      </a-col>
      <a-col>
        <label class="form-item-lable">Nhân viên</label>
        <a-select
          style="width: 100%"
          placeholder="Chọn nhân viên"
          mode="multiple"
          allowClear
          showSearch
          @change="handleChangeStaff"
          :filterOption="filterOption"
        >
          <a-select-option v-for="staff in staffOptions" :value="staff.id" :key="staff.id">{{
            staff.fullName
          }}</a-select-option>
        </a-select>
      </a-col>
      <a-col>
        <label class="form-item-lable">Số lần mời dùng dịch vụ</label>
        <a-input type="number" :value="params.numberFree" @input="params.numberFree = $event.target.value" />
      </a-col>
    </a-row>
    <a-row>
      <a-col :md="12">
        <label class="form-item-lable">Giảm giá MP (%)</label>
        <a-input
          :value="params.productDiscountPercent"
          @input="params.productDiscountPercent = $event.target.value"
          type="number"
        />
      </a-col>
      <a-col :md="12">
        <label class="form-item-lable">Giảm giá DV (%)</label>
        <a-input
          :value="params.serviceDiscountPercent"
          @input="params.serviceDiscountPercent = $event.target.value"
          type="number"
        />
      </a-col>
    </a-row>
    <a-row>
      <a-col>
        <label class="form-item-lable">Lý do</label>
        <a-select
          style="width: 100%"
          allowClear
          showSearch
          :filterOption="filterOption"
          mode="multiple"
          @change="handleChangeReason"
        >
          <a-select-option v-for="item in errorCutReasonOptions" :value="item.id" :key="item.id">{{
            item.reason
          }}</a-select-option>
        </a-select>
      </a-col>
      <a-col>
        <label class="form-item-lable">Ghi chú</label>
        <a-textarea allow-clear :value="params.reason" @input="params.reason = $event.target.value" />
      </a-col>
    </a-row>
    <template slot="footer">
      <a-button key="back" @click="handleCancel">
        Hủy bỏ
      </a-button>
      <a-button key="submit" type="primary" :loading="loading" @click="onSubmit">
        Lưu lại
      </a-button>
    </template>
  </a-modal>
</template>
<script>
import { mixin, mixinDevice } from '@/utils/mixin'
import moment from 'moment'
import { mapGetters } from 'vuex'
import Utils from '@/utils/others'
import { RepositoryFactory } from '@/api/RepositoryFactory'
const CustomerRepo = RepositoryFactory.get('customerRepository')
const CustomerSpecialRepository = RepositoryFactory.get('customerSpecial')
const StaffRepo = RepositoryFactory.get('staff')
const SalonRepo = RepositoryFactory.get('salon')
const ErrorCutReasonRepo = RepositoryFactory.get('errorCutReason')

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  mixins: [mixin, mixinDevice],
  computed: {
    localVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    ...mapGetters(['user'])
  },
  data() {
    return {
      loading: false,
      data: [],
      salonOptions: [],
      staffOptions: [],
      errorCutReasonOptions: [],
      staffs: [],
      salons: [],

      fromDate: moment(),
      customerName: undefined,
      params: {
        customerId: undefined,
        typeCustomerSpecialId: 1,
        cutErrorDate: undefined,
        salonId: undefined,
        numberFree: 0,
        productDiscountPercent: 0,
        serviceDiscountPercent: 100,
        reason: undefined,
        staffErrors: undefined,
        typeErrors: undefined
      }
    }
  },
  watch: {
    'params.numberFree': function(value) {
      value = parseInt(value)
      if (Number.isNaN(value) || !Number.isInteger(value) || value < 0) {
        this.params.numberFree = 0
      } else {
        if (value > 100) {
          this.$message.warn('Vui lòng nhập giá trị nhỏ hơn hoặc bằng 100')
          this.params.numberFree = 0
          return
        }
        this.params.numberFree = value
      }
    },
    'params.productDiscountPercent': function(value) {
      value = parseInt(value)
      if (Number.isNaN(value) || !Number.isInteger(value) || value < 0) {
        this.params.productDiscountPercent = 0
      } else {
        if (value > 100) {
          this.$message.warn('Vui lòng nhập giá trị nhỏ hơn hoặc bằng 100')
          this.params.productDiscountPercent = 0
          return
        }
        this.params.productDiscountPercent = value
      }
    },
    'params.serviceDiscountPercent': function(value) {
      value = parseInt(value)
      if (Number.isNaN(value) || !Number.isInteger(value) || value < 0) {
        this.params.serviceDiscountPercent = 0
      } else {
        if (value > 100) {
          this.$message.warn('Vui lòng nhập giá trị nhỏ hơn hoặc bằng 100')
          this.params.serviceDiscountPercent = 0
          return
        }
        this.params.serviceDiscountPercent = value
      }
    }
  },
  methods: {
    handleChangeCutDate(date, dateString) {
      this.params.cutErrorDate = dateString
    },

    handleCancel() {
      this.localVisible = false
    },

    async onSubmit() {
      try {
        if (!this.isValidInput()) {
          return
        }
        this.loading = true
        const response = await CustomerSpecialRepository.getByCustomerId(this.params.customerId)
        if (response && response.isExists) {
          const isConfirm = await Utils.confirm(this.$confirm, {
            title: 'Xác nhận',
            content: 'Khách hàng tồn tại trong danh sách, bạn có chắc chắn muốn cập nhật?'
          })
          if (!isConfirm) {
            this.loading = false
            return
          }
        }

        await CustomerSpecialRepository.create(this.params)
        this.$message.success('Thêm mới thành công.')
        this.localVisible = false
        this.$emit('realoadData', true)
      } catch (error) {
        console.log(error)
      }
      this.loading = false
    },

    isValidInput() {
      if (!this.params.customerId) {
        this.$message.warn('Vui lòng nhập số điện thoại.')
        return false
      }
      if (!this.params.cutErrorDate) {
        this.$message.warn('Vui lòng chọn ngày cắt lỗi.')
        return false
      }
      if (!this.params.salonId) {
        this.$message.warn('Vui lòng chọn salon.')
        return false
      }
      if (!this.params.staffErrors || this.params.staffErrors.length === 0) {
        this.$message.warn('Vui lòng chọn nhân viên.')
        return false
      }

      if (!this.params.typeErrors || this.params.typeErrors.length === 0) {
        this.$message.warn('Vui lòng chọn lý do.')
        return false
      }

      if (!this.params.reason) {
        this.$message.warn('Vui lòng nhập lý do.')
        return false
      }
      return true
    },

    async handleChangePhone(e) {
      const phone = e.target.value
      if (!phone || (phone && phone.length !== 10)) {
        this.$message.warn('Vui lòng nhập đúng số điện thoại')
        return
      }
      try {
        this.customerName = undefined
        const response = await CustomerRepo.getByPhone(phone)
        this.customerName = response.fullname
        this.params.customerId = response.id
      } catch (error) {
        console.log(error)
      }
    },

    async getSalon() {
      try {
        if (this.$can(this.$permission.viewAllData, this.$route.path)) {
          const response = await SalonRepo.getListSelect()
          this.salonOptions = response
        } else {
          this.salonOptions = this.user.listSalon.map(x => {
            return { id: x.id, shortName: x.salonName }
          })
        }
      } catch (error) {
        console.log(error)
      }
    },

    async getStaff() {
      try {
        const response = await StaffRepo.getListSelect()
        this.staffs = response
      } catch (error) {
        console.log(error)
      }
    },

    async getErrorCutReason() {
      const response = await ErrorCutReasonRepo.getListSelect()
      this.errorCutReasonOptions = response
    },

    handleChangeSalon(value) {
      this.staffOptions = this.staffs.filter(x => x.salonId === value)
      this.params.salonId = value
    },

    handleChangeStaff(value) {
      if (value) {
        this.params.staffErrors = this.staffOptions.filter(x => {
          return value.indexOf(x.id) !== -1
        })
        console.log(this.params.staffErrors)
      }
    },

    handleChangeReason(value) {
      if (value) {
        this.params.typeErrors = this.errorCutReasonOptions.filter(x => {
          return value.indexOf(x.id) !== -1
        })
        console.log(this.params.typeErrors)
      }
    },

    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  },
  created() {
    this.getSalon()
    this.getStaff()
    this.getErrorCutReason()
  }
}
</script>
<style scoped>
.form-item-input {
  margin: 5px 0 5px 0;
  width: 100%;
}
.form-item-lable {
  display: inline-block;
  margin: 5px 0 5px 0;
}
</style>
